// src/components/Header.js

import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Menu,
  Avatar,
  Box,
  Fade,
} from '@mui/material';
import { alpha, useTheme, styled, keyframes } from '@mui/material/styles';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../assets/logo.svg'; // Adjust path to your logo


// Define keyframes for gradient animation
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Styled Typography for Animated Gradient Brand Name
const AnimatedBrand = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  background: 'linear-gradient(270deg, #ff8a00, #e52e71, #9b00ff)',
  backgroundSize: '600% 600%',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${gradientAnimation} 8s ease infinite`,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

// Styled Button with modern design
const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  padding: theme.spacing(1, 3),
  borderRadius: '25px',
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: '500',
  background: 'linear-gradient(45deg, #ff8a00, #e52e71)',
  color: '#fff',
  transition: 'background 0.3s, transform 0.3s',
  '&:hover': {
    background: 'linear-gradient(45deg, #e52e71, #9b00ff)',
    transform: 'scale(1.05)',
  },
}));

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const theme = useTheme();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenu = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMobileMenuAnchorEl(null);
  };

  // Event handlers for menu items
  const handleProfile = () => {
    handleClose();
    navigate('/profile');
  };

  const handleMyAccount = () => {
    handleClose();
    navigate('/my-account');
  };

  const handleLogout = async () => {
    handleClose();
    try {
      await logout();
      navigate('/'); // Redirect to Landing Page after logout
    } catch (error) {
      console.error('Failed to log out:', error);
      // Optionally, display an error message to the user
    }
  };

  // Handler for "Get Start" button
  const handleGetStart = () => {
    navigate('/register');
  };

  // Mobile menu
  const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      id={mobileMenuId}
      anchorEl={mobileMenuAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleClose}
      TransitionComponent={Fade}
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          color: '#fff',
          mt: 2,
          '& .MuiMenuItem-root': {
            '&:hover': {
              backgroundColor: alpha('#ffffff', 0.1),
            },
          },
        },
      }}
    >
      {currentUser ? (
        <>
          <MenuItem onClick={handleProfile}>Profile</MenuItem>
          <MenuItem onClick={handleMyAccount}>My Account</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
          <MenuItem component="a" href="https://buymeacoffee.com/num8ers" target="_blank" rel="noopener noreferrer">
            📖 Support us
          </MenuItem>
        </>
      ) : (
        <>
          <MenuItem onClick={() => { handleClose(); navigate('/login'); }}>Login</MenuItem>
          <MenuItem onClick={handleGetStart}>Signup</MenuItem>
          <MenuItem component="a" href="https://buymeacoffee.com/num8ers" target="_blank" rel="noopener noreferrer">
            📖 Support us
          </MenuItem>
        </>
      )}
    </Menu>
  );

  return (
    <AppBar
      position="fixed"
      component="header"
      sx={{
        backgroundColor: '#000',
        boxShadow: 'none',
        borderBottom: `1px solid ${alpha('#ffffff', 0.1)}`,
        zIndex: theme.zIndex.drawer + 1,
        height: '80px',
        justifyContent: 'center',
        paddingX: { xs: 2, sm: 4, md: 6 },
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Brand Name */}
        <AnimatedBrand
          variant="h6"
          component={RouterLink}
          to="/"
          aria-label="Latest Exam Papers Home"
          sx={{
            textDecoration: 'none',
          }}
        >
          Latest Exam Papers
        </AnimatedBrand>
          
        {/* Desktop Menu */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
          {currentUser ? (
            <>
              <StyledButton onClick={handleProfile}>Profile</StyledButton>
              <StyledButton onClick={handleMyAccount}>My Account</StyledButton>
              <StyledButton onClick={handleLogout}>Logout</StyledButton>
              <StyledButton
                component="a"
                href="https://buymeacoffee.com/num8ers"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ marginLeft: theme.spacing(3) }}
              >
                📖 Support us
              </StyledButton>
            </>
          ) : (
            <>
              <StyledButton onClick={() => navigate('/login')}>Login</StyledButton>
              <StyledButton onClick={handleGetStart}>Signup</StyledButton>
              <StyledButton
                component="a"
                href="https://buymeacoffee.com/num8ers"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ marginLeft: theme.spacing(3) }}
              >
                📖 Support us
              </StyledButton>
            </>
          )}
        </Box>

        {/* Mobile Menu Icon */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            edge="end"
            aria-label="menu"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Render Mobile Menu */}
      {renderMobileMenu}
    </AppBar>
  );
};

export default Header;
