// src/components/Footer.js

import React from 'react';
import { Box, Typography, Link, Grid, TextField, IconButton, Button } from '@mui/material';
import { styled, keyframes, useTheme } from '@mui/material/styles';
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
} from '@mui/icons-material';

// Styled Button with modern design extending Material-UI's Button
const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1, 3),
  borderRadius: '25px',
  textTransform: 'none',
  fontSize: '1.1rem',
  fontWeight: '500',
  background: 'linear-gradient(45deg, #ff8a00, #e52e71)',
  color: '#fff',
  border: 'none',
  cursor: 'pointer',
  transition: 'background 0.3s, transform 0.3s',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    background: 'linear-gradient(45deg, #e52e71, #9b00ff)',
    transform: 'scale(1.05)',
  },
  '&:focus': {
    outline: 'none',
  },
}));

// Define keyframes for gradient animation
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Styled Typography for Animated Gradient Text
const AnimatedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  background: 'linear-gradient(270deg, #ff8a00, #e52e71, #9b00ff)',
  backgroundSize: '600% 600%',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${gradientAnimation} 8s ease infinite`,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

function Footer() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        px: 2,
        mt: 'auto',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Grid container spacing={4} justifyContent="space-between">
        {/* Information Links */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            About Us
          </Typography>
          <Typography 
           variant="h6"
           //variant="body2" 
           component="p" sx={{ color: theme.palette.text.secondary, fontSize: '1rem', margin: '0 auto',}}>
            We provide FREE study notes, books, and past paper questions to help you nail those exams! Latest Exam Papers is your go-to platform for past papers, textbooks, study notes, and practice worksheets for IB, AP, GCSE, IGCSE, O Level, A Level, AS Level, and K-12.
          </Typography>
        </Grid>

        {/* Newsletter Subscription */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Subscribe to our Newsletter
          </Typography>
          <Box component="form" sx={{ mt: 2 }}>
            <TextField
              label="Email Address"
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.background.paper,
                  '& fieldset': {
                    borderColor: theme.palette.divider,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.text.primary,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: theme.palette.primary.main,
                },
              }}
              aria-label="Email Address"
            />
            <StyledButton type="submit">
              Subscribe
            </StyledButton>
          </Box>
        </Grid>

        {/* Social Media Links */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Follow Us
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
            <IconButton
              component="a"
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: theme.palette.text.primary,
                width: '48px',
                height: '48px',
                borderRadius: '12px',
                backgroundColor: '#ffffff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                  color: '#3b5998',
                },
              }}
              aria-label="Facebook"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: theme.palette.text.primary,
                width: '48px',
                height: '48px',
                borderRadius: '12px',
                backgroundColor: '#ffffff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                  color: '#00acee',
                },
              }}
              aria-label="Twitter"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: theme.palette.text.primary,
                width: '48px',
                height: '48px',
                borderRadius: '12px',
                backgroundColor: '#ffffff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                  color: '#C13584',
                },
              }}
              aria-label="Instagram"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.youtube.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: theme.palette.text.primary,
                width: '48px',
                height: '48px',
                borderRadius: '12px',
                backgroundColor: '#ffffff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                  color: '#c4302b',
                },
              }}
              aria-label="YouTube"
            >
              <YouTubeIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* Footer Bottom */}
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <AnimatedTypography variant="body2">
          © 2024-25 NUM8ERS. All rights reserved.
        </AnimatedTypography>
        <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>
          {'Built with love by '}
          <Link color="inherit" href="#" target="_blank" rel="noopener noreferrer">
            NUM8ERS
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
