// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateEmail,
  updatePassword,
  sendEmailVerification,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';

const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider component that wraps your application and provides authentication functions
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // To handle async auth state

  // Effect to handle authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false); // Loading is complete
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  // Authentication Functions

  /**
   * Registers a new user with email and password.
   * Sends an email verification after successful registration.
   * @param {string} email - User's email address
   * @param {string} password - User's password
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  const signup = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Send email verification
      await sendEmailVerification(userCredential.user);
      return userCredential;
    } catch (error) {
      console.error('Error during signup:', error);
      throw error;
    }
  };

  /**
   * Logs in an existing user with email and password.
   * @param {string} email - User's email address
   * @param {string} password - User's password
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential;
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  /**
   * Logs out the current user.
   * @returns {Promise<void>}
   */
  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error during logout:', error);
      throw error;
    }
  };

  /**
   * Updates the current user's email.
   * @param {string} email - New email address
   * @returns {Promise<void>}
   */
  const updateUserEmail = async (email) => {
    try {
      await updateEmail(currentUser, email);
    } catch (error) {
      console.error('Error updating email:', error);
      throw error;
    }
  };

  /**
   * Updates the current user's password.
   * @param {string} password - New password
   * @returns {Promise<void>}
   */
  const updateUserPassword = async (password) => {
    try {
      await updatePassword(currentUser, password);
    } catch (error) {
      console.error('Error updating password:', error);
      throw error;
    }
  };

  /**
   * Re-authenticates the current user with their password.
   * Useful for sensitive operations like changing email or password.
   * @param {string} currentPassword - User's current password
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  const reauthenticate = async (currentPassword) => {
    try {
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        currentPassword
      );
      const userCredential = await reauthenticateWithCredential(currentUser, credential);
      return userCredential;
    } catch (error) {
      console.error('Error during reauthentication:', error);
      throw error;
    }
  };

  /**
   * Sends a password reset email to the specified email address.
   * Uses custom actionCodeSettings to redirect to your custom handler.
   * @param {string} email - User's email address
   * @returns {Promise<void>}
   */
  const sendPasswordResetEmail = async (email) => {
    // Define actionCodeSettings with your custom URL
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: `${window.location.origin}/auth-action/resetPassword`,
      // This must be true for email link sign-in.
      handleCodeInApp: true,
    };

    try {
      await firebaseSendPasswordResetEmail(auth, email, actionCodeSettings);
      console.log('Password reset email sent successfully.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  const value = {
    currentUser,
    signup,
    login,
    logout,
    updateUserEmail,
    updateUserPassword,
    reauthenticate,
    sendPasswordResetEmail, // Add the password reset function to the context
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
