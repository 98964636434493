import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBx_heBJo--TEklW4azcEsWim8GBeCGmm8",
  authDomain: "past-papers-mania.firebaseapp.com",
  projectId: "past-papers-mania",
  storageBucket: "past-papers-mania.appspot.com",
  messagingSenderId: "898952150790",
  appId: "1:898952150790:web:08dddfcd1ce95accf1e887",
  measurementId: "G-VGK3GPJLTB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log('Firebase Config:', firebaseConfig); // Remove after debugging
// Initialize services
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;