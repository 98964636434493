// src/components/AuthActionHandler.js

import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  getAuth,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';
import {
  Container,
  Box,
  Typography,
  TextField,
  Alert,
  Fade,
  Paper,
  Avatar,
  CircularProgress,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled, keyframes } from '@mui/material/styles';
import { Helmet } from 'react-helmet'; // For SEO

// Define keyframes for gradient animation
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Styled Typography for Animated Gradient Title
const AnimatedTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  background: 'linear-gradient(270deg, #ff8a00, #e52e71, #9b00ff)',
  backgroundSize: '600% 600%',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${gradientAnimation} 8s ease infinite`,
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem', // Larger on medium and up screens
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem', // Smaller on small screens
  },
}));

// Styled Button with modern design
const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5, 4),
  borderRadius: '30px',
  textTransform: 'none',
  fontSize: '1.25rem',
  fontWeight: '600',
  background: 'linear-gradient(45deg, #ff8a00, #e52e71)',
  color: '#fff',
  transition: 'background 0.3s, transform 0.3s',
  '&:hover': {
    background: 'linear-gradient(45deg, #e52e71, #9b00ff)',
    transform: 'scale(1.05)',
  },
}));

// Styled Avatar to match color scheme
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  background: 'linear-gradient(45deg, #ff8a00, #e52e71)',
}));

// Styled Typography for messages
const MessageTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: '1.1rem',
}));

function AuthActionHandler() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = getAuth();

  // Extract query parameters
  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');

  // State variables
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state during async operations
  const [newPassword, setNewPassword] = useState(''); // For password reset
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility

  useEffect(() => {
    // Handle the email action based on the mode
    const handleAction = async () => {
      if (!mode || !oobCode) {
        setError('Invalid request. Missing parameters.');
        setLoading(false);
        return;
      }

      switch (mode) {
        case 'verifyEmail':
          await handleVerifyEmail();
          break;
        case 'resetPassword':
          await handleResetPassword();
          break;
        default:
          setError('Invalid action mode.');
          setLoading(false);
      }
    };

    handleAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, oobCode]); // Re-run if mode or oobCode changes

  // Function to handle email verification
  const handleVerifyEmail = async () => {
    try {
      await applyActionCode(auth, oobCode);
      setMessage('Your email has been successfully verified.');
      setError(null);
      // Redirect after a delay to allow users to read the message
      setTimeout(() => {
        navigate('/login'); // Redirect to login page
      }, 3000);
    } catch (err) {
      console.error('Error verifying email:', err);
      setError('Failed to verify email. The link may have expired or is invalid.');
      setMessage(null);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle password reset
  const handleResetPassword = async () => {
    try {
      // Verify the password reset code is valid
      await verifyPasswordResetCode(auth, oobCode);
      setMessage('Please enter your new password below.');
      setError(null);
    } catch (err) {
      console.error('Error verifying password reset code:', err);
      setError('Invalid or expired password reset link.');
      setMessage(null);
    } finally {
      setLoading(false);
    }
  };

  // Function to confirm password reset
  const confirmResetPassword = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);

    if (newPassword.length < 6) {
      setError('Password should be at least 6 characters long.');
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setMessage('Your password has been successfully reset. You can now log in.');
      setError(null);
      // Redirect after a delay to allow users to read the message
      setTimeout(() => {
        navigate('/login'); // Redirect to login page
      }, 3000);
    } catch (err) {
      console.error('Error resetting password:', err);
      setError('Failed to reset password. The link may have expired or is invalid.');
      setMessage(null);
    }
  };

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Authentication Action - Latest Exam Papers</title>
        <meta
          name="description"
          content="Complete your authentication action to access the latest exam papers and study materials."
        />
      </Helmet>

      <Container component="main" maxWidth="sm">
        <Fade in={true} timeout={1000}>
          <Paper
            elevation={6}
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 6,
              borderRadius: 4,
              background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
            }}
          >
            <StyledAvatar>
              <LockOutlinedIcon />
            </StyledAvatar>
            {/* Animated Gradient Title */}
            <AnimatedTitle component="h1" variant="h5" gutterBottom>
              {mode === 'verifyEmail' ? 'Email Verification' : 'Reset Password'}
            </AnimatedTitle>
            {/* Display error message */}
            {error && (
              <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                {error}
              </Alert>
            )}
            {/* Display success message */}
            {message && (
              <Alert severity="success" sx={{ width: '100%', mt: 2 }}>
                {message}
              </Alert>
            )}
            {/* If mode is resetPassword and the code is valid, show the reset form */}
            {mode === 'resetPassword' && !error && message && (
              <Box
                component="form"
                onSubmit={confirmResetPassword}
                sx={{ mt: 3, width: '100%' }}
              >
                <TextField
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="newPassword"
                  autoComplete="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  aria-label="new password"
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      backgroundColor: '#ffffff',
                    },
                    fontSize: '1.2rem',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={showPassword ? 'Hide password' : 'Show password'}
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  sx={{
                    width: '100%', // Ensure the button takes full width
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Reset Password'
                  )}
                </StyledButton>
              </Box>
            )}
          </Paper>
        </Fade>
      </Container>
    </>
  );
}

export default AuthActionHandler;
