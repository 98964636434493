// src/App.js

import React, { Suspense, lazy } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Typography, CircularProgress } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import theme from './theme';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import AuthActionHandler from './components/AuthActionHandler';
import ForgotPassword from './components/ForgotPassword';

// Lazy-loaded components
const LandingPage = lazy(() => import('./components/LandingPage'));
const SearchTool = lazy(() => import('./components/SearchTool'));
const Register = lazy(() => import('./components/Register'));
const Login = lazy(() => import('./components/Login'));
const Profile = lazy(() => import('./components/Profile'));
const MyAccount = lazy(() => import('./components/MyAccount'));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <Box component="main"  >
            <div style={{ paddingTop: '64px' }}>
              <div>
                <Suspense fallback={<CircularProgress />}>
                  <Routes>
                  <Route path="/auth-action/:mode/:oobCode" element={<AuthActionHandler />} />
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route element={<PrivateRoute />}>
                      <Route path="/search" element={<SearchTool />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/my-account" element={<MyAccount />} />
                    </Route>
                    {/* Catch-all route for undefined paths */}
                    <Route path="*" element={<Typography variant="h4" align="center">404 - Page Not Found</Typography>} />
                  </Routes>
                </Suspense>
              </div>
              </div>
            </Box>
            <div style={{ paddingTop: '60px' }}></div>
            <Footer />
          </Box>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
