// src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#9b00ff', // Vibrant purple for primary elements
    },
    secondary: {
      main: '#e52e71', // Complementary pink for secondary elements
    },
    background: {
      default: '#f0f2f5', // Soft light grey background
      paper: '#ffffff', // White background for paper components
    },
    text: {
      primary: '#333333', // Dark text for readability
      secondary: '#757575', // Grey text for secondary information
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // Consistent font across the application
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
      [createTheme().breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },
    h6: {
      fontWeight: 400,
      fontSize: '1.25rem',
      [createTheme().breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    body1: {
      fontSize: '1rem',
      [createTheme().breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    // Add more typography variants as needed
  },
  spacing: 8, // Default spacing unit
  components: {
    // Global component customizations can be added here
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px', // Consistent button border radius
          textTransform: 'none', // Maintain original casing
          fontWeight: 600, // Semi-bold text
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px', // Consistent input border radius
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '12px', // Consistent chip border radius
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '50%', // Circular icon buttons
        },
      },
    },
    // Add more component customizations as needed
  },
});

export default theme;
