// src/components/ForgotPassword.js

import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Alert,
  Fade,
  Paper,
  Avatar,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // For SEO
import { styled, keyframes } from '@mui/material/styles';
import Button from '@mui/material/Button'; // Import Button separately if needed

// Define keyframes for gradient animation
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Styled Typography for Animated Gradient Title
const AnimatedTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  background: 'linear-gradient(270deg, #ff8a00, #e52e71, #9b00ff)',
  backgroundSize: '600% 600%',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${gradientAnimation} 8s ease infinite`,
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem', // Larger on medium and up screens
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem', // Smaller on small screens
  },
}));

// Styled Button with modern design
const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5, 4),
  borderRadius: '30px',
  textTransform: 'none',
  fontSize: '1.25rem',
  fontWeight: '600',
  background: 'linear-gradient(45deg, #ff8a00, #e52e71)',
  color: '#fff',
  transition: 'background 0.3s, transform 0.3s',
  '&:hover': {
    background: 'linear-gradient(45deg, #e52e71, #9b00ff)',
    transform: 'scale(1.05)',
  },
}));

// Styled Link for Login
const StyledLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  color: '#e52e71',
  fontWeight: 'bold',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

// Styled Avatar to match color scheme
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  background: 'linear-gradient(45deg, #ff8a00, #e52e71)',
}));

const ForgotPassword = () => {
  const { sendPasswordResetEmail } = useAuth(); // Utilizing sendPasswordResetEmail from AuthContext
  const navigate = useNavigate();

  // Form state
  const [email, setEmail] = useState('');

  // Error and success state
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  // Loading state
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    // Basic client-side validation
    if (!email.trim()) {
      setError('Please enter your email address.');
      return;
    }

    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setLoading(true);
    try {
      await sendPasswordResetEmail(email);
      setMessage('Password reset email has been sent. Please check your inbox.');
      setError('');
      // Optionally, navigate to login after a delay
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (err) {
      console.error('Error sending password reset email:', err);
      // Handle specific Firebase errors
      if (err.code === 'auth/user-not-found') {
        setError('No user found with this email.');
      } else if (err.code === 'auth/invalid-email') {
        setError('The email address is not valid.');
      } else {
        setError('Failed to send password reset email. Please try again later.');
      }
      setMessage('');
    }
    setLoading(false);
  };

  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Forgot Password - Latest Exam Papers</title>
        <meta
          name="description"
          content="Forgot your password? Reset it here to access the latest exam papers and study materials."
        />
      </Helmet>

      <Container component="main" maxWidth="sm">
        <Fade in={true} timeout={1000}>
          <Paper
            elevation={6}
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 6,
              borderRadius: 4,
              background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
            }}
          >
            <StyledAvatar>
              <LockOutlinedIcon />
            </StyledAvatar>
            {/* Animated Gradient Title */}
            <AnimatedTitle component="h1" variant="h5" gutterBottom>
              Forgot Password
            </AnimatedTitle>
            {error && (
              <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                {error}
              </Alert>
            )}
            {message && (
              <Alert severity="success" sx={{ width: '100%', mt: 2 }}>
                {message}
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 3, width: '100%' }}
            >
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="email"
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    backgroundColor: '#ffffff',
                  },
                  fontSize: '1.2rem',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* Optional: Add an email icon */}
                      {/* <IconButton edge="end">
                        <EmailIcon />
                      </IconButton> */}
                    </InputAdornment>
                  ),
                }}
              />
              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  width: '100%', // Ensure the button takes full width
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Send Reset Email'
                )}
              </StyledButton>
              <Typography
                variant="body2"
                align="center"
                sx={{ fontSize: '1rem', mt: 1 }}
              >
                Remembered your password?{' '}
                <StyledLink to="/login">Log in here</StyledLink>
              </Typography>
            </Box>
          </Paper>
        </Fade>
      </Container>
    </>
  );
};

export default ForgotPassword;
